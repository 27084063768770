<template>
  <button
    @click="click"
    :class="[
      { 'qt-loader qt-loader-mini qt-loader-right': loading },
      `btn btn-${type}`,
    ]"
    :disabled="loading"
  >
    <slot>Button</slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonElement',
  props: {
    type: {
      type: String,
      required: false,
      default: 'info',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    click() {
      this.$emit('click', this);
    },
  },
};
</script>
