import axios from '@/plugins/axios';

class BlacklistService {
  export(data) {
    this.data = data;
    return axios().post('blacklist/export', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }
}

export default new BlacklistService();
