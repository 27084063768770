<template>
  <div class="content">
    <PageHeader title="RCS Deny list" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-6 col-lg-4 col-sm-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button
                        v-modal="{ target: 'create-blacklist-modal' }"
                        class="btn btn-success"
                      >
                        <i class="icon dripicons-plus"></i>{{$t('generic-str.lbl-create-new')}}
                      </button>
                      <button-element @click="download"
                        ><i class="icon dripicons-plus"></i
                        >{{$t('generic-str.export')}}</button-element
                      >
                      <button
                        class="btn btn-danger btn-remove border-radius-right"
                        :class="{
                          'qt-loader qt-loader-mini qt-loader-right': isSending,
                        }"
                        @click="deleteAll"
                        v-bind:disabled="
                          selectedBlacklists.length == 0 || isSending
                        "
                      >
                        <i class="icon dripicons-trash color-white"></i>{{$t('generic-str.delete')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!blacklists.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i class="fas fa-ban font-size-80" />
                  <h5 v-html="$t('warnings.blacklist.none-deny')" class="card-title m-t-20"></h5>
                  <p v-html="$t('warnings.blacklist.register-deny')"></p>
                </div>
                <div
                  class="custom-control custom-checkbox text-right hide show-m all-check-mobile"
                >
                  <input
                    v-model="selectAllBlacklists"
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheckTAll"
                  />
                  <label
                    class="custom-control-label v-align-top"
                    for="customCheckTAll"
                  ></label>
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="blacklists.length" class="bg-light">
                    <tr>
                      <th class="w-60" scope="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectAllBlacklists"
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheckTAll"
                          />
                          <label
                            class="custom-control-label v-align-top"
                            for="customCheckTAll"
                          ></label>
                        </div>
                      </th>
                      <th scope="col" class="text-left">{{$tc('generic-str.lbl-number', 1)}}</th>
                      <th scope="col" class="text-left">{{$t('generic-str.expire')}}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(blacklist, i) in blacklists"
                      :key="blacklist.id"
                    >
                      <td data-label="">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectedBlacklists"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'blacklist-check' + i"
                            :value="blacklist"
                          />
                          <label
                            class="custom-control-label v-align-top"
                            :for="'blacklist-check' + i"
                          ></label>
                        </div>
                      </td>
                      <td data-label="Número: " class="text-left m-text-right">
                        <div
                          class="max-200"
                          v-tooltip.top="`${blacklist.mobile_number}`"
                        >
                          {{ blacklist.mobile_number }}
                        </div>
                      </td>
                      <td
                        v-if="blacklist.expired_at"
                        data-label="Expira em: "
                        class="text-left m-text-right"
                      >
                        {{ blacklist.expired_at }}
                      </td>
                      <td
                        v-else
                        data-label="Expira em: "
                        class="text-left m-text-right"
                      >
                        {{$t('generic-str.never')}}
                      </td>
                      <td data-label="" class="text-right">
                        <div class="dropdown" style="margin-right: 10px">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style=""
                          >
                            <a
                              class="dropdown-item"
                              v-modal="{
                                target: 'blacklist-modal',
                                data: blacklist,
                              }"
                              >{{$t('generic-str.edit')}}</a
                            >
                            <a
                              class="dropdown-item"
                              @click="remove(blacklist.id, blacklist.mobile_number)"
                              >{{$t('generic-str.remove')}}</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <create-blacklist-modal id="create-blacklist-modal" @submit="fetch()" />
    <blacklist-modal id="blacklist-modal" @submit="fetch()" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import CreateBlacklistModal from '@/components/rcs/CreateBlacklistModal.vue';
import BlacklistModal from '@/components/rcs/BlacklistModal.vue';
import SmsService from '@/services/sms.service';
import BlacklistService from '@/services/blacklist.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    CreateBlacklistModal,
    BlacklistModal,
    Pagination,
    ButtonElement,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      blacklists: [],
      form: {
        page: 1,
        channel: 'rcs',
      },
      pages: 1,
      selectedBlacklists: [],
    };
  },
  computed: {
    selectAllBlacklists: {
      get() {
        if (this.templates) {
          return this.selectedBlacklists.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.templates.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedBlacklists = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.form.page = page;
      this.form.channel = 'rcs';
      this.fetched = false;
      SmsService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    selectedBlacklistsChange(param) {
      this.selectedBlacklists = param.selectedBlacklists;
    },
    remove(id, number) {
      Swal.fire({
        title: this.$t('warnings.blacklist.remove-deny'),
        text: `${this.$t('warnings.optouts.text-number-2')} ${number}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          toDelete.push(id);
          this.isSending = true;
          SmsService.deleteBlacklists({ ids: toDelete }).then(() => {
            this.fetch();
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.blacklist.removed-deny'),
              type: 'success',
            });
            this.$root.$emit('updated.blacklist');
            this.isSending = false;
          });
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('warnings.blacklist.remove-deny'),
        text: this.$t('warnings.blacklist.text-deny'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedBlacklists.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteBlacklists({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.blacklist.removed-deny'),
                type: 'success',
              });
              this.$root.$emit('updated.blacklist');
              this.isSending = false;
              this.selectedBlacklists = [];
            },
            (error) => {
              console.log(error);
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
    download(e) {
      e.loading = true;
      BlacklistService.export()
        .then(
          (data) => {
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) {
              // IE 10+
              navigator.msSaveBlob(blob, 'denylist.csv');
            } else {
              const link = document.createElement('a');
              if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'denylist.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          },
          (error) => {
            this.content = error;
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          e.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
</style>
